import React, { useCallback, useEffect, useRef, useState } from "react";
import cinstance from "../api/axios";
import { Breadcrumb } from "../component/Breadcrumb";
import { CardRight, CardLeft, InfoCard } from "../component/Card";
import { UserLayout } from "../Layout/UserLayout";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ClientPracticeArea = () => {
  const [globaldata, setGlobalData] = useState()
  const [allPracticesData, setAllPracticesData] = useState(null);
  const language_web = useSelector((state) => state.webLanguage.value)
  const location = useLocation();
  const passedId = location.hash.split('#')[1]

  const getPractices = useCallback(async () => {
    try {
      await cinstance({
        url: `/practice/all-practice`,
        method: "GET",
      }).
        then((res) => {
          setAllPracticesData([])

          res.data.map((value, id) => {
            if (value.status == "active")
              setAllPracticesData((prev) => [...prev, value]);

          })
        });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const goToViolation = (id) => {
      const violation = document.getElementById(id);
      window.scrollTo({
        top: violation?.offsetTop,
        behavior: "smooth"
      });
    };
    goToViolation(passedId)
  }, [passedId])

  const getGlobalData = useCallback(async () => {
    try {
      await cinstance({
        url: "/page-intro/find/63e393105a0223b065c4b045",
        method: "GET",
      }).then((res) => {
        setGlobalData(res.data)
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getPractices();
    getGlobalData();
    return () => { };
  }, []);

  return (
    <UserLayout>
      <Breadcrumb image={"practice-banner"} />
      <div className="flex justify-center xl:py-[70px]">
        <InfoCard
          title={language_web === "ARE" ? ("تخصصاتنا") : ("Our Specialties")}
          heading={language_web === "ARE" ? ("ما نحن خبراء فيه") : ("WHAT WE ARE EXPERT AT")}
          paragragh={globaldata?.[language_web]?.practice}
        />
      </div>
      <div className="">
        {allPracticesData?.map((value, idx) => (
          <div key={idx}>
            {idx % 2 == 0 ? (
              (<section>
                <CardRight
                  id={value?.en?.title}
                  title={value?.[language_web]?.title}
                  Content={value?.[language_web]?.desc}
                  src={value?.en?.img}
                  alt=""
                />
              </section>)

            ) : (
              (<section>
                <CardLeft
                  id={value?.en?.title}
                  title={value?.[language_web]?.title}
                  Content={value?.[language_web]?.desc}
                  src={value?.en?.img}
                  alt=""
                />
              </section>)

            )}
          </div>
        ))}
      </div>

    </UserLayout>
  );
};

export default ClientPracticeArea;
